import { apolloClient } from '../lib/withApollo'

const graphqlQuery = async (
  query,
  siteOrApi = null,
  variables = null,
  authToken = null
) => {
  const client = apolloClient()
  const context = { site: siteOrApi, authToken }

  if (query.definitions[0].operation === 'mutation') {
    return client
      .mutate({
        mutation: query,
        context,
        variables,
      })
      .catch((error) => {
        return { error: error.message, originalError: error }
      })
  }

  const { data, error } = await client.query({
    query,
    context,
    variables,
  })
  if (error) console.log('graphQl request error', error)
  // TODO: error handling here

  return data
}

export default graphqlQuery
